// @flow

/**
 * Module dependencies.
 */

import { Container, Grid } from 'components/core/layout';
import { type SlykApiConfig, usePayspace } from 'hooks/use-payspace';
import { ifProp } from 'styled-tools';
import { media, units } from '@seegno-labs/react-components/styles';
import { useIntersection } from 'hooks/use-intersection';
import Details, {
  type DetailsProps
} from 'components/core/details';

import Image from 'components/core/images/image';
import MobileAppPreview, {
  mobileVideoHeight,
  videoHeight
} from 'components/mobile-app-preview';

import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  ...DetailsProps,
  fluidImage?: ?Object,
  invertLayout?: boolean,
  mobileFluidImage?: Node,
  slug: ?string,
  slykApiConfig: ?SlykApiConfig
|};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  &:first-of-type {
    padding-top: ${units(4)};

    @media (min-width: 360px) {
      padding-top: ${units(8)};
    }

    ${media.min('md')`
      padding-top: 122px;
    `}
  }

  &:not(:last-of-type) {
    margin-bottom: ${units(3)};

    @media (min-width: 360px) {
      margin-bottom: ${units(6.5)};
    }

    ${media.min('md')`
      margin-bottom: ${units(30)};
    `}
  }

  &:nth-last-of-type(2) {
    margin-bottom: ${units(3)};

    @media (min-width: 360px) {
      margin-bottom: ${units(6.5)};
    }

    ${media.min('md')`
      margin-bottom: ${units(39)};
    `}
  }
`;

/**
 * `MediaDesktopWrapper` styled component.
 */

const MediaDesktopWrapper = styled.div`
  display: none;

  ${media.min('md')`
    display: grid;
    grid-column: 1 / 8;
    grid-row: 1;
    grid-template-rows: ${videoHeight}px;
    position: relative;
    right: 15%;

    ${ifProp('invertLayout', css`
      grid-column: 6 / -1;
      left: 10%;
      right: auto;
    `)}
  `}
`;

/**
 * `MediaMobileWrapper` styled component.
 */

const MediaMobileWrapper = styled.div`
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: auto;
  padding-top: ${units(3)};
  position: relative;

  @media (min-width: 360px) {
    padding-top: ${units(7)};
  }

  ${media.min('md')`
    display: none;
  `}
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled(Image)`
  grid-column: 1;
  grid-row: 1;
  height: ${units(64)};
  max-width: 706px;
  width: 100%;

  ${media.min('ms')`
    justify-self: ${ifProp('invertLayout', 'start', 'end')};
  `}
`;

/**
 * `MobileAppPreviewWrapper` styled component.
 */

const MobileAppPreviewWrapper = styled.div`
  grid-column: 1;
  grid-row: 1;
  height: ${mobileVideoHeight}px;
  justify-self: ${ifProp('invertLayout', 'start', 'end')};
  max-width: 320px;
  position: relative;

  ${media.max('xs')`
    height: auto;
    justify-self: center;
  `}
`;

/**
 * `MobileAppAnimatedPreviewWrapper` styled component.
 */

const MobileAppAnimatedPreviewWrapper = styled(MobileAppPreviewWrapper)`
  transition: transform 1s ease-in-out;

  ${ifProp('isIntersecting', css`
    transform: scale(1);
  `, css`
    transform: scale(0.95);
  `)}

  ${media.min('xs')`
    transform: scale(1);
  `}
`;

/**
 * `ContentWrapper` styled component.
 */

const ContentWrapper = styled.div`
  grid-column: 1 / -1;
  position: relative;

  ${media.min('ms')`
    grid-column: 2 / -2;
  `}

  ${media.min('md')`
    display: block;
    grid-column: ${ifProp('invertLayout', '3', '8')} / span 4;
    grid-row: 1;
    margin-bottom: 0;
  `}
`;

/**
 * `StyledDetails` styled component.
 */

const StyledDetails = styled(Details)`
  grid-row-gap: ${units(3)};

  ${media.min('md')`
    align-content: center;
    height: 100%;
  `}
`;

/**
 * `SectionDetails` component.
 */

function SectionDetails(props: Props): Node {
  const {
    buttonLabel,
    buttonUrl,
    description,
    fluidImage,
    invertLayout,
    mobileFluidImage,
    slug,
    slykApiConfig,
    title
  } = props;

  const {
    data: payspace,
    loading
  } = usePayspace(slug, slykApiConfig);

  const { isIntersecting, ref } = useIntersection();

  return (
    <Section>
      <Container>
        <Grid>
          <MediaDesktopWrapper invertLayout={invertLayout}>
            <StyledImage
              alt={''}
              fluid={fluidImage}
              invertLayout={invertLayout}
            />

            <MobileAppPreviewWrapper invertLayout={invertLayout}>
              <MobileAppPreview
                isLoading={loading}
                payspace={payspace}
              />
            </MobileAppPreviewWrapper>
          </MediaDesktopWrapper>

          <ContentWrapper invertLayout={invertLayout}>
            <StyledDetails
              buttonLabel={buttonLabel}
              buttonUrl={buttonUrl}
              description={description}
              fontWeight={600}
              title={title}
            />

            <MediaMobileWrapper invertLayout={invertLayout}>
              <StyledImage
                alt={''}
                fluid={mobileFluidImage}
                invertLayout={invertLayout}
              />

              <MobileAppAnimatedPreviewWrapper
                invertLayout={invertLayout}
                isIntersecting={isIntersecting}
                ref={ref}
              >
                <MobileAppPreview
                  isLoading={loading}
                  payspace={payspace}
                />
              </MobileAppAnimatedPreviewWrapper>
            </MediaMobileWrapper>
          </ContentWrapper>
        </Grid>
      </Container>
    </Section>
  );
}

/**
 * Export `SectionDetails` component.
 */

export default SectionDetails;
