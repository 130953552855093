// @flow

/**
 * Module dependencies.
 */

import { Box, Fill, Grid } from 'components/core/layout';
import { Icon } from '@seegno-labs/react-components/media';
import { Type } from '@seegno-labs/react-components/typography';
import { color, units } from '@seegno-labs/react-components/styles';
import { getAcronym } from 'utils/acronym';
import { ifProp, theme } from 'styled-tools';
import BackgroundImage from 'components/core/images/background-image';
import Button from 'components/core/button';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import maskOutlineIcon from 'assets/svg/mask-outline.svg';
import styled, { css } from 'styled-components';

/**
 * Export `StoryProps` type.
 */

export type StoryProps = {|
  buttonLabel: string,
  category: {
    content: { title: string },
    id: string
  },
  description: string,
  image: Object,
  invertLayout?: boolean,
  logo: Object,
  storyUrl: string,
  title: string
|};

/**
 * Export `StoryPreviewProps` type.
 */

export type StoryPreviewProps = {|
  image: Object,
  logo: Object,
  tag: string,
  title: string
|};

/**
 * `Info` styled component.
 */

const Info = styled.div`
  align-items: center;
  color: ${color('white')};
  display: flex;
  height: ${units(6)};
  left: ${units(3)};
  max-width: calc(100% - ${units(4)});
  position: absolute;
  top: ${units(3)};
`;

/**
 * `Logo` styled component.
 */

const Logo = styled.div`
  background-color: ${color('secondary')};
  clip-path: url(#slyk-mask);
  color: ${color('white')};
  height: ${units(6)};
  margin-right: ${units(2)};
  overflow: hidden;
  position: relative;
  width: ${units(6)};

  ${ifProp({ hasImage: false }, css`
    align-items: center;
    display: flex;
    justify-content: center;
  `)}
`;

/**
 * `StorePreviewTitle` styled component.
 */

const StorePreviewTitle = styled.p`
  font-family: ${theme('typography.fontFamily.saira')};
  font-size: 16px;
  font-weight: 400;
  letter-spacing: -0.2px;
  line-height: 22px;
`;

/**
 * `StoryPreviewWrapper` styled component.
 */

const StoryPreviewWrapper = styled.div`
  background-color: ${color('grey200')};
  border-radius: ${units(1)};
  overflow: hidden;
  padding-bottom: 81.6725978648%;
  position: relative;
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled(Fill)`
  ::after {
    background-color: ${color.transparentize('secondary', 0.15)};
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
`;

/**
 * `Tag` styled component.
 */

const Tag = styled.div`
  background-color: ${color.transparentize('secondary', 0.55)};
  border-radius: 3px;
  bottom: ${units(2)};
  color: ${color('white')};
  font-family: ${theme('typography.fontFamily.saira')};
  font-size: 12px;
  font-weight: 400;
  left: ${units(2)};
  letter-spacing: -0.2px;
  line-height: 18px;
  overflow: hidden;
  padding: ${units(0.5)} ${units(1)};
  position: absolute;
  text-transform: uppercase;
`;

/**
 * `IconBorder` styled component.
 */

const IconBorder = styled(Icon)`
  color: ${color('white')};
  margin-left: -1px;
  opacity: 0.8;
  position: absolute;
  z-index: 1;

  svg {
    overflow: visible;
  }
`;

/**
 * Export `StoryPreview` component.
 */

export function StoryPreview(props: StoryPreviewProps) {
  const { image, logo, tag, title } = props;

  return (
    <StoryPreviewWrapper>
      <ImageWrapper>
        <BackgroundImage fluid={image} />
      </ImageWrapper>

      <Info>
        <IconBorder
          icon={maskOutlineIcon}
          size={'50px'}
        />

        <Logo hasImage={logo}>
          {logo ? <BackgroundImage fluid={logo} /> : getAcronym(title)}
        </Logo>

        <StorePreviewTitle>
          {title}
        </StorePreviewTitle>
      </Info>

      <Tag>
        {tag}
      </Tag>
    </StoryPreviewWrapper>
  );
}

/**
 * `Story` component.
 */

function Story(props: StoryProps): Node {
  const {
    buttonLabel,
    category,
    description,
    image,
    invertLayout,
    logo,
    storyUrl,
    title
  } = props;

  return (
    <Grid>
      <Box
        gridColumn={'1 / -1'}
        gridColumnMd={invertLayout ? '1 / span 6' : '7 / -1'}
        gridColumnMs={invertLayout ? '1 / span 6' : '7 / -1'}
        gridRow={1}
        marginBottom={units(3)}
        marginBottomMs={0}
      >
        <StoryPreview
          image={image?.file?.childImageSharp?.fluid}
          logo={logo?.file?.childImageSharp?.fluid}
          tag={category?.content?.title}
          title={title}
        />
      </Box>

      <Box
        alignSelf={'center'}
        gridColumn={'1 / -1'}
        gridColumnMd={invertLayout ? '8 / -1' : '1 / span 5'}
        gridColumnMs={invertLayout ? '7 / -1' : '1 / span 6'}
        gridRow={2}
        gridRowMs={1}
      >
        <Type.H2
          as={'h5'}
          color={color('secondary')}
          marginBottom={units(3)}
        >
          <RawHtml>
            {title}
          </RawHtml>
        </Type.H2>

        <Type.Label
          as={'div'}
          color={color('grey800')}
          marginBottom={units(3)}
        >
          <RawHtml>
            {description}
          </RawHtml>
        </Type.Label>

        <Button
          href={storyUrl}
          rel={'external noopener'}
          size={'small'}
          target={'_blank'}
        >
          {buttonLabel}
        </Button>
      </Box>
    </Grid>
  );
}
/**
 * Export `Story` component.
 */

export default Story;
