// @flow

/**
 * Module dependencies.
 */

import { Container, Grid } from 'components/core/layout';
import type { Image } from 'types/image';
import { find } from 'lodash';
import { media, units } from '@seegno-labs/react-components/styles';
import Link from 'components/core/links/link';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  imageList: Array<{|
    id: string,
    image: Image,
    link: ?string
  |}>,
  textList: Array<{|
    id: string,
    image: Image,
    label: string,
    link?: string
  |}>
|};

/**
 * `Content` styled component.
 */

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  grid-column: 1 / -1;
  justify-content: center;
  margin-bottom: ${units(8)};

  ${media.min('ms')`
    gap: 40px;
    grid-column: 3 / -3;
  `}

  ${media.min('lg')`
    grid-column: 1 / -1;
    margin-bottom: ${units(21.25)};

    > a {
      max-width: 30%;
    }
  `}

  ${media.min('xl')`
    > a {
      max-width: initial;
    }
  `}
`;

/**
 * `LogoSection` component.
 */

function LogoSection({ imageList, textList }: Props): Node {
  return (
    <Container>
      <Grid>
        <Content>
          {textList.map(({ id, label, link }) => {
            const imageItem = find(imageList, item => item.id === id);

            return (
              <Link
                aria-label={link ? label : undefined}
                href={link ? link : undefined}
                key={id}
                rel={'noopener'}
                target={'_blank'}
              >
                <img
                  alt={''}
                  aria-hidden
                  height={'100%'}
                  src={imageItem.image.file.publicURL}
                  width={'100%'}
                />
              </Link>
            );
          })}
        </Content>
      </Grid>
    </Container>
  );
}

/**
 * Export `LogoSection` component.
 */

export default LogoSection;
