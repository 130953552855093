// @flow

/**
 * Module dependencies.
 */

import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { prop } from 'styled-tools';
import Button from 'components/core/button';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * Export `DetailsProps` type.
 */

export type DetailsProps = {|
  buttonLabel?: ?string,
  buttonUrl?: ?string,
  description: ?string,
  fontWeight?: number,
  title: ?string
|};

/**
 * `Props` type.
 */

type Props = {|
  ...DetailsProps,
  className?: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  display: grid;
  grid-row-gap: ${units(4)};
  justify-items: start;
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H2)`
  color: ${color('secondary')};
  font-weight: ${prop('fontWeight', 300)};

  ${media.min('md')`
    font-weight: ${prop('fontWeight', 300)};
  `}
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.H6).attrs({ as: 'div' })`
  color: ${color('secondary')};

  ${media.min('md')`
    max-width: ${units(40.5)};
  `}
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  font-weight: 400;
  min-height: 54px;
  width: 100%;

  ${media.min('ms')`
    min-height: 42px;
    padding: ${units(1)} ${units(4)};
    width: initial;
  `}
`;

/**
 * `Details` component.
 */

function Details(props: Props): Node {
  const {
    buttonLabel,
    buttonUrl,
    className,
    description,
    fontWeight,
    title
  } = props;

  return (
    <Wrapper className={className}>
      {title && (
        <Title fontWeight={fontWeight}>
          <RawHtml>
            {title}
          </RawHtml>
        </Title>
      )}

      {description && (
        <Description>
          <RawHtml>
            {description}
          </RawHtml>
        </Description>
      )}

      {buttonLabel && buttonUrl && (
        <StyledButton
          colorTheme={'primary'}
          href={buttonUrl}
          size={'medium'}
        >
          {buttonLabel}
        </StyledButton>
      )}
    </Wrapper>
  );
}

/**
 * Export `Details` component.
 */

export default Details;
