// @flow

/**
 * Module dependencies.
 */

import { Box, Container, Grid } from 'components/core/layout';
import { Type } from '@seegno-labs/react-components/typography';
import { color, units } from '@seegno-labs/react-components/styles';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  className?: string,
  description?: string,
  title: string
|};

/**
 * `Title` styled component.
 */

const Title = styled(Type.H1).attrs({ as: 'h3' })`
  color: ${color('secondary')};
  margin-bottom: ${units(2)};

  > span > p {
    > strong, > b {
      color: ${color('blue500')};
    }
  }
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.H5).attrs({ as: 'div' })`
  color: ${color('secondary')};
`;

/**
 * `SectionHeading` component.
 */

function SectionHeading(props: Props): Node {
  const { className, description, title } = props;

  return (
    <Container>
      <Grid>
        <Box
          className={className}
          gridColumn={'1 / -1'}
          gridColumnMd={'4 / -4'}
          textAlign={'center'}
        >
          <Title>
            <RawHtml>
              {title}
            </RawHtml>
          </Title>

          {description && (
            <Description>
              <RawHtml>
                {description}
              </RawHtml>
            </Description>
          )}
        </Box>
      </Grid>
    </Container>
  );
}

/**
 * Export `SectionHeading` component.
 */

export default SectionHeading;
