// @flow

/**
 * Module dependencies.
 */

import { Box } from 'components/core/layout';
import { Card } from 'components/core/card';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import Button from 'components/core/button';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  buttonLabel: string,
  buttonUrl: string,
  description: string,
  icon: string,
  title: string
|};

/**
 * `CardWrapper` styled component.
 */

const CardWrapper = styled(Card)`
  background-color: ${color('white')};
  padding: ${units(9.5)} ${units(2)} ${units(4)};

  ${media.min('md')`
    padding: ${units(3)} ${units(4)};
  `}

  ${media.min('lg')`
    padding: ${units(4.5)} ${units(8)};
  `}
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.div`
  text-align: center;

  ${media.min('xs')`
    align-items: center;
    display: grid;
    grid-gap: ${units(3)};
    grid-template-areas: 'icon' 'content' 'actions';
    justify-content: center;
  `}

  ${media.min('md')`
    grid-template-areas: 'icon content actions';
    grid-template-columns: min-content 1fr min-content;
    text-align: left;
  `}
`;

/**
 * `StyledImage` styled component.
 */

const StyledImage = styled.img`
  color: ${color('indigo500')};
  grid-area: icon;
  height: ${units(14)};
  width: ${units(14)};

  ${media.max('md')`
    left: 50%;
    position: absolute;
    top: 0;
    transform: translate(-50%, -50%);
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H4).attrs({ as: 'div' })`
  color: ${color('indigo500')};
  margin-bottom: ${units(2)};

  ${media.min('ms')`
    margin-bottom: ${units(0.5)};
  `}
`;

/**
 * `CardSection` component.
 */

function CardSection(props: Props): Node {
  const {
    buttonLabel,
    buttonUrl,
    description,
    icon,
    title
  } = props;

  return (
    <CardWrapper>
      <Grid>
        <StyledImage
          alt={''}
          src={icon}
        />

        <Box
          gridArea={'content'}
          marginBottom={units(4)}
          marginBottomSm={0}
        >
          <Title>
            <RawHtml>
              {title}
            </RawHtml>
          </Title>

          <Type.H6 as={'div'}>
            <RawHtml>
              {description}
            </RawHtml>
          </Type.H6>
        </Box>

        <Box gridArea={'actions'}>
          <Button
            colorTheme={'indigo'}
            to={buttonUrl}
          >
            {buttonLabel}
          </Button>
        </Box>
      </Grid>
    </CardWrapper>
  );
}

/**
 * Export `CardSection` component.
 */

export default CardSection;
