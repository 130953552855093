// @flow

/**
 * Module dependencies.
 */

import { Box, Container, Fill, Grid } from 'components/core/layout';
import { StoryPreview } from 'components/payspaces/story';
import { Type } from '@seegno-labs/react-components/typography';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { map, slice } from 'lodash';
import { theme } from 'styled-tools';
import Button from 'components/core/button';
import RawHtml from '@seegno-labs/react-components/raw-html';
import React, { type Node } from 'react';
import styled from 'styled-components';
import useBreakpoint from 'hooks/use-breakpoint';

/**
 * `Props` type.
 */

type Props = {|
  buttonLabel: ?string,
  buttonUrl: ?string,
  description: ?string,
  stories: Array<Object>,
  title: ?string
|};

/**
 * `Section` styled component.
 */

const Section = styled.section`
  margin-bottom: ${units(4)};

  @media (min-width: 360px) {
    margin-bottom: ${units(8)};
  }

  ${media.min('ms')`
    margin-bottom: ${units(14)};
  `}

  ${media.min('lg')`
    margin-bottom: ${units(20)};
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  grid-column: 1 / -1;
  margin-bottom: ${units(4)};
  text-align: center;

  ${media.min('ms')`
    margin-bottom: ${units(7)};
  `}

  ${media.min('md')`
    grid-column: 4 / -4;
  `}

  ${media.min('xl')`
    margin-bottom: ${units(9)};
  `}

  ${media.min('xxl')`
    margin-bottom: ${units(10)};
  `}
`;

/**
 * `Title` styled component.
 */

const Title = styled(Type.H1).attrs({ as: 'h3' })`
  color: ${color('secondary')};
  margin-bottom: ${units(2)};
`;

/**
 * `Description` styled component.
 */

const Description = styled(Type.H6).attrs({ as: 'div' })`
  color: ${color('secondary')};
`;

/**
 * `StoryLink` styled component.
 */

const StoryLink = styled.a`
  cursor: pointer;
  outline: none;
  position: relative;

  &:focus,
  &:hover {
    --story-link-opacity: 1;
  }
`;

/**
 * `Overlay` styled component.
 */

const Overlay = styled(Fill)`
  align-items: center;
  background-color: ${color.transparentize('secondary', 0.5)};
  border-radius: ${units(1)};
  display: flex;
  justify-content: center;
  opacity: var(--story-link-opacity, 0);
  overflow: hidden;
  transition: opacity ${theme('animations.defaultTransition')};

  ${Button} {
    pointer-events: none;
    width: auto;
  }
`;

/**
 * `StoriesGrid` styled component.
 */

const StoriesGrid = styled.div`
  display: grid;
  grid-gap: ${units(4)};
  grid-template-columns: 1fr;
  margin-bottom: ${units(4)};

  @media (min-width: 360px) {
    margin-bottom: ${units(8)};
  }

  ${media.min('ms')`
    display: grid;
    grid-gap: ${units(2)};
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr 1fr;
  `}

  ${media.min('lg')`
    grid-gap: ${units(4)};
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 1fr;
  `}
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  font-weight: 400;
  min-height: 54px;
  width: 100%;

  ${media.min('ms')`
    min-height: 42px;
    padding: ${units(1)} ${units(4)};
    width: initial;
  `}
`;

/**
 * `StoriesSection` component.
 */

function StoriesSection(props: Props): Node {
  const { buttonLabel, buttonUrl, description, stories, title } = props;
  const isMobile = useBreakpoint('ms', 'max');

  return (
    <Section>
      <Container>
        <Grid>
          <Content>
            <Title>
              <RawHtml>
                {title}
              </RawHtml>
            </Title>

            {description && (
              <Description>
                <RawHtml>
                  {description}
                </RawHtml>
              </Description>
            )}
          </Content>
        </Grid>
      </Container>

      <Container>
        <StoriesGrid>
          {map(slice(stories, 0, 4), story => (
            <StoryLink
              href={story?.storyUrl}
              key={story?.id}
              rel={'external noopener'}
              target={'_blank'}
            >
              <StoryPreview
                image={story?.image?.file?.childImageSharp?.fluid}
                logo={story?.logo?.file?.childImageSharp?.fluid}
                tag={story?.category?.content?.title}
                title={story?.title}
              />

              <Overlay>
                <Button
                  as={'div'}
                  colorTheme={'primary'}
                  size={'small'}
                >
                  {story?.buttonLabel}
                </Button>
              </Overlay>
            </StoryLink>
          ))}
        </StoriesGrid>

        <Box textAlign={'center'}>
          {buttonLabel && buttonUrl && (
            <StyledButton
              colorTheme={'primary'}
              href={buttonUrl}
              size={isMobile ? 'large' : 'medium'}
            >
              {buttonLabel}
            </StyledButton>
          )}
        </Box>
      </Container>
    </Section>
  );
}

/**
 * Export `StoriesSection` component.
 */

export default StoriesSection;
