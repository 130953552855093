// @flow

/**
 * Module dependencies.
 */

import { Container, Grid } from 'components/core/layout';
import { type TemplateProps } from 'types/template';
import { VideosContext } from 'components/mobile-app-preview/video-preview';
import { getFluidImage } from 'utils/get-fluid-image';
import { graphql, useStaticQuery } from 'gatsby';
import { media, units } from '@seegno-labs/react-components/styles';
import CardSection from 'components/card-section';
import FooterIconsSection from 'components/footer/footer-icons-section';
import Header from 'components/core/header';
import LogoSection from 'components/home/logo-section';
import PageContainer from 'components/page-container';
import React, { type Node, useMemo, useRef, useState } from 'react';
import SectionDetails from 'components/home/section-details';
import SectionHeading from 'components/home/section-heading';
import StoriesSection from 'components/home/stories-section';
import styled from 'styled-components';

/**
 * Home query.
 */

const homeQuery = graphql`
  query {
    config {
      api {
        slyk {
          baseUrl
          endpoints {
            payspace
          }
        }
      }
    }
    data: allSeegnoCmsHomePage {
      nodes {
        content {
          header {
            backgroundImage {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
            image {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
          }
          logoSection {
            list {
              id
              image {
                file {
                  publicURL
                }
              }
              label
              link
            }
          }
          cardSection {
            icon {
              file {
                publicURL
              }
            }
          }
          section1 {
            image {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
          }
          section2 {
            image {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
          }
          section3 {
            image {
              file {
                childImageSharp {
                  ...ResponsiveImage
                }
              }
            }
          }
          footerSection {
            buttonLabel
            buttonUrl
            leftIcon {
              file {
                publicURL
              }
            }
            leftText
            rightIcon {
              file {
                publicURL
              }
            }
            rightText
          }
        }
      }
    }
    stories: allSeegnoCmsStoryEntity(filter: {
      content: {
        featured: {
          eq: true
        }
      }
    }) {
      nodes {
        content {
          buttonLabel
          description
          storyUrl
          title
          image {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }
          logo {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }
          category {
            id
            content {
              title
            }
          }
        }
        id
      }
    }
  }
`;

/**
 * `CardSectionWrapper` styled component.
 */

const CardSectionWrapper = styled.div`
  grid-column: 1 / -1;
  margin-bottom: ${units(8)};

  ${media.min('ms')`
    grid-column: 2 / -2;
    margin-bottom: ${units(15)};
  `}

  ${media.min('md')`
    transform: translateY(-20%);
  `}

  ${media.min('xxl')`
    transform: translateY(30%);
  `}
`;

/**
 * Get home title.
 */

function getHomeTitle(title: ?string): ?string {
  if (!title) {
    return;
  }

  if (title.includes('in minutes')) {
    return title.replace('in minutes', 'in&nbsp;minutes');
  }

  return title;
}

/**
 * `Home` template.
 */

function Home({ pageContext }: TemplateProps): Node {
  const homeData = useStaticQuery(homeQuery);
  const content = pageContext?.content;
  const metatags = pageContext?.metatags;
  const dataContent = homeData?.data?.nodes[0]?.content;
  const header = content?.header;
  const cardSection = content?.cardSection;
  const section1 = content?.section1;
  const section2 = content?.section2;
  const section3 = content?.section3;
  const normalizedStories = useMemo(() => {
    const stories = homeData?.stories?.nodes ?? [];

    return stories.map(({ content, id }) => ({ id, ...content }));
  }, [homeData]);

  const [isMuted, setIsMuted] = useState(true);
  const videosList = useRef([]);
  const sortedVideos = useMemo(() => {
    return [
      header?.slug,
      section1?.slug,
      section2?.slug,
      section3?.slug
    ];
  }, [header, section1, section2, section3]);

  return (
    <PageContainer
      footerElement={<FooterIconsSection {...dataContent?.footerSection} />}
      metatags={metatags}
    >
      <VideosContext.Provider
        value={{
          isMuted,
          setIsMuted,
          sortedVideos,
          videosList: videosList.current
        }}
      >
        <Header
          backgroundFluidImage={getFluidImage(dataContent?.header, 'backgroundImage')}
          buttonLabel={header?.buttonLabel}
          buttonUrl={header?.buttonUrl}
          description={header?.description}
          fluidImage={getFluidImage(dataContent?.header, 'image')}
          slug={header?.slug}
          slykApiConfig={homeData?.config?.api?.slyk}
          title={getHomeTitle(header?.title)}
        />

        <Container>
          <Grid>
            <CardSectionWrapper>
              <CardSection
                buttonLabel={cardSection?.buttonLabel}
                buttonUrl={cardSection?.buttonUrl}
                description={cardSection?.description}
                icon={dataContent?.cardSection?.icon?.file?.publicURL}
                title={cardSection?.title}
              />
            </CardSectionWrapper>
          </Grid>
        </Container>

        <LogoSection
          imageList={dataContent?.logoSection?.list}
          textList={content.logoSection.list}
        />

        <SectionHeading
          description={content.sectionsHeading.description}
          title={content.sectionsHeading.title}
        />

        {!section1?.isHidden && (
          <SectionDetails
            fluidImage={getFluidImage(dataContent?.section1)}
            mobileFluidImage={getFluidImage(dataContent?.section1)}
            slykApiConfig={homeData?.config?.api?.slyk}
            {...section1}
          />
        )}

        {!section2?.isHidden && (
          <SectionDetails
            fluidImage={getFluidImage(dataContent?.section2)}
            invertLayout
            mobileFluidImage={getFluidImage(dataContent?.section2)}
            slykApiConfig={homeData?.config?.api?.slyk}
            {...section2}
          />
        )}

        {!section3?.isHidden && (
          <SectionDetails
            fluidImage={getFluidImage(dataContent?.section3)}
            mobileFluidImage={getFluidImage(dataContent?.section3)}
            slykApiConfig={homeData?.config?.api?.slyk}
            {...section3}
          />
        )}

        <StoriesSection
          buttonLabel={content.storiesSection.buttonLabel}
          buttonUrl={content.storiesSection.buttonUrl}
          description={content.storiesSection.description}
          stories={normalizedStories}
          title={content.storiesSection.title}
        />
      </VideosContext.Provider>
    </PageContainer>
  );
}

/**
 * Export `Home` template.
 */

export default Home;
