// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import { color, media, units } from '@seegno-labs/react-components/styles';
import { theme } from 'styled-tools';
import Button from 'components/core/button';
import React, { type Node } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  buttonLabel?: string,
  buttonUrl?: string,
  leftIcon: {
    file: {
      publicURL: string
    }
  },
  leftText: string,
  rightIcon: {
    file: {
      publicURL: string
    }
  },
  rightText: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  color: ${color('primary')};
  padding: ${units(8)} 0 ${units(10)};
  position: relative;
  text-align: center;

  ${media.min('md')`
    padding: ${units(16.25)} 0 ${units(20)};
  `}
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  ${theme('typography.styles.h2')}

  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;

  ${media.min('md')`
    max-width: 75%;
  `}
`;

/**
 * `FlexLabelWrapper` styled component.
 */

const FlexLabelWrapper = styled.div`
  display: flex;
  gap: ${units(3)};
  margin-bottom: 105px;

  ${media.max('ms')`
    flex-flow: column;
  `}
`;

/**
 * `FlexLabel` styled component.
 */

const FlexLabel = styled.span`
  align-items: center;
  column-gap: ${units(3)};
  display: flex;
  justify-content: center;
`;

/**
 * `IconImage` styled component.
 */

const IconImage = styled.img`
  height: 1.5em;
  width: 1.5em;
`;

/**
 * `FooterIconsSection` component.
 */

function FooterIconsSection(props: Props): Node {
  const {
    buttonLabel,
    buttonUrl,
    leftIcon,
    leftText,
    rightIcon,
    rightText
  } = props;

  return (
    <Wrapper>
      <Container>
        <Content>
          <FlexLabelWrapper>
            <FlexLabel>
              <IconImage
                alt={''}
                src={leftIcon.file.publicURL}
              />

              {leftText}
            </FlexLabel>

            <FlexLabel>
              <IconImage
                alt={''}
                src={rightIcon.file.publicURL}
              />

              {rightText}
            </FlexLabel>
          </FlexLabelWrapper>

          {buttonUrl && buttonLabel && (
            <Button to={buttonUrl}>
              {buttonLabel}
            </Button>
          )}
        </Content>
      </Container>
    </Wrapper>
  );
}

/**
 * Export `FooterIconsSection` component.
 */

export default FooterIconsSection;
